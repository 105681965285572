import React, { Component } from "react";
import { EmptyInfo, Button } from "../../../../../common";
import moment from "moment";
import "./newCollaborationDetail.scss";
import { Avatar, Col, Row, Typography } from "antd";
const { Title, Text } = Typography;

class NewCollaborationDetail extends Component {
  renderDate = (date) => {
    return moment(date).format("MM/DD/YY");
  };

  render() {
    const {
      selectedCollaboration,
      emptyCollaorationInfo,
      startDateLabel,
      durationLabel,
      compensationLabel,
      selectedCompany,
      descriptionLabel,
      isIproActiveCollaboration,
      selectInterest,
      onCollaborationAccept,
      onCollaborationDecline,
      onCollaborationDelete,
      onCollaborationClose,
      IsSearcher,
      isInActiveView,
      isIproNewCollaboration,
      isSentView,
      isIproInActiveCollaboration,
      isAcceptedView,
      iProOpportunityNewDetailBtnAccept,
      iProOpportunityNewDetailBtnDecline,
      labels,
      addressLabel,
      industryLabel,
      countryLabel,
      phoneLabel,
      webUrlLabel,
      vatLabel
    } = this.props;
    return (
      <div className="new-collaboration-detail-component">
        <div
          className="d-flex justify-content-between gap-8 align-items-center"
          style={{ padding: "16px", borderBottom: "1px solid #f4f2fe" }}
        >
          <Title level={5} className="m-0 text-clamp flex-1">
            {selectedCollaboration.CollaborationId
              ? selectedCollaboration?.Title
              : ""}
          </Title>
          {((IsSearcher && isAcceptedView) ||
            (!IsSearcher && isIproActiveCollaboration)) && (
              <Title
                level={5}
                style={{ color: "#FF6363", cursor: "pointer" }}
                className="m-0"
                onClick={onCollaborationClose}
                >
                End Collaboration
              </Title>
            )}
          {IsSearcher && isSentView && (
            <Title
              level={5}
              style={{ color: "#FF6363", cursor: "pointer" }}
              className="m-0"
              onClick={onCollaborationDelete}
              >
              Delete Collaboration
            </Title>
          )}
        </div>
        {!selectedCollaboration.CollaborationId ? (
          <EmptyInfo>{emptyCollaorationInfo}</EmptyInfo>
        ) : (
          <div className="collaboration-detail">
            <div className="workplace-detail">
              <Row gutter={[16, 8]}>
                <Col xs={24}>
                  <Title level={5}>
                    {selectedCollaboration.company.CompanyName || labels.NOT_AVAILABLE_LABEL}
                  </Title>
                </Col>
                <Col xs={8}>
                  <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                    {startDateLabel}:
                  </Text>
                  <div className="mt-1">
                    <Text className="fnt-s-14 ">
                      {this.renderDate(selectedCollaboration.StartDate) ||
                        labels.NOT_AVAILABLE_LABEL}
                    </Text>
                  </div>
                </Col>
                <Col xs={8}>
                  <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                    {durationLabel}:
                  </Text>
                  <div className="mt-1">
                    <Text className="fnt-s-14 ">
                      {selectedCollaboration.Duration || labels.NOT_AVAILABLE_LABEL}
                    </Text>
                  </div>
                </Col>
                <Col xs={8}>
                  <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                    {compensationLabel}:
                  </Text>
                  <div className="mt-1">
                    <Text className="fnt-s-14 ">
                      {selectedCollaboration.HourlyRate ||
                        labels.NOT_AVAILABLE_LABEL}{" "}
                      &nbsp;
                      {selectedCollaboration.HourlyRateType}{" "}
                    </Text>
                  </div>
                </Col>
                <Col xs={24}>
                  <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                    {descriptionLabel}:
                  </Text>
                  <div className="mt-1">
                    <Text className="fnt-s-14 ">
                      {" "}
                      {selectedCollaboration.Decription}
                    </Text>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="ipro-details mt-3">
              {IsSearcher ? (
                <>
                  <p className="user-title">{labels?.ipro_details}</p>
                  <div className=" d-flex align-items-center gap-16">
                    <div>
                      <Avatar
                        size={86}
                        src={
                          selectedCollaboration?.userdetail?.Logo ||
                          labels.NOT_AVAILABLE_LABEL
                        }
                      />
                    </div>
                    <p className="user-title">{selectedCollaboration?.userdetail?.Title}</p>
                  </div>
                  <div className=" mt-3 details">
                    <div>
                      <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                        {labels?.SearcherAcceptedCollaborationUserNameLabel}:
                      </Text>
                      <div className="mt-1">
                        <Text className="fnt-s-14 ">{selectedCollaboration?.userdetail?.UserName || labels.NOT_AVAILABLE_LABEL}</Text>
                      </div>
                    </div>
                    <div>
                      <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                        {labels?.IproOpportunityNewUserWebURL}
                      </Text>
                      <div className="mt-1">
                        <Text className="fnt-s-14 ">{selectedCollaboration?.userdetail?.WebUrl || labels.NOT_AVAILABLE_LABEL}</Text>
                      </div>
                    </div>
                  </div>
                  <div className=" mt-3">
                    <div>
                      <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                        {labels?.ViewTitleFeedBackDetail}
                      </Text>
                      <div className="mt-1">
                        <Text className="fnt-s-14 ">{selectedCollaboration?.userdetail?.Detail || labels.NOT_AVAILABLE_LABEL}</Text>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className="user-title">{labels?.company_details}</p>
                  <div className=" d-flex align-items-center gap-16">
                    <div>
                      <Avatar
                        size={86}
                        src={
                          selectedCompany?.Logo ||
                          labels.NOT_AVAILABLE_LABEL
                        }
                      />
                    </div>
                    <p className="user-title">{selectedCompany?.Industry?.IndustryValue}</p>
                  </div>
                  <div className=" mt-3 details">
                    <div className=" flex-1">
                      <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                        {addressLabel}:
                      </Text>
                      <div className="mt-1">
                        <Text className="fnt-s-14 ">{selectedCompany?.CompanyAddress || labels.NOT_AVAILABLE_LABEL}</Text>
                      </div>
                    </div>
                    <div className=" flex-1">
                      <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                        {countryLabel}:
                      </Text>
                      <div className="mt-1">
                        <Text className="fnt-s-14 ">{selectedCompany?.CompanyCountry?.CountryName || labels.NOT_AVAILABLE_LABEL}</Text>
                      </div>
                    </div>
                    <div className=" flex-1">
                      <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                        {phoneLabel}:
                      </Text>
                      <div className="mt-1">
                        <Text className="fnt-s-14 ">{selectedCompany?.UserPhonenumber?.UserPhonenumberValue || labels.NOT_AVAILABLE_LABEL}</Text>
                      </div>
                    </div>
                    {selectedCompany && selectedCompany?.WebUrl && (
                      <div className=" flex-1">
                        <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                          {webUrlLabel}:
                        </Text>
                        <div className="mt-1">
                          <a className=" fnt-s-14" target="_blank" href={selectedCompany?.WebUrl}>
                            {selectedCompany?.WebUrl}
                          </a>
                        </div>
                      </div>
                    )}
                    <div className=" flex-1">
                      <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                        {vatLabel}:
                      </Text>
                      <div className="mt-1">
                        <Text className="fnt-s-14 ">{selectedCompany?.Vat || labels.NOT_AVAILABLE_LABEL}</Text>
                      </div>
                    </div>
                  </div>

                  <div className=" mt-3">
                    <div>
                      <Text className="fnt-s-12" style={{ color: "#00000066" }}>
                        {labels?.ViewTitleFeedBackDetail}
                      </Text>
                      <div className="mt-1">
                        <Text className="fnt-s-14 ">{selectedCollaboration?.userdetail?.Detail || labels.NOT_AVAILABLE_LABEL}</Text>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="">
                {!IsSearcher && isIproNewCollaboration && (
                  <div>
                    <Row
                      gutter={[8, 12]}
                      style={{ marginTop: "10px", flexFlow: "unset" }}
                    >
                      <Col flex="auto">
                        <p className="user-detail" style={{ fontWeight: 600 }}>
                          {selectInterest}:
                        </p>
                      </Col>
                    </Row>

                    <div className="btn-footer-ipro">
                      <button
                        className="btn-delete clr-purple "
                        onClick={() => onCollaborationAccept()}
                      >
                        {iProOpportunityNewDetailBtnAccept}
                      </button>
                      <button
                        className="btn-delete clr-red ml-2"
                        onClick={() => onCollaborationDecline()}
                      >
                        {iProOpportunityNewDetailBtnDecline}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NewCollaborationDetail;
